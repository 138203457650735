import { contentChild, Directive, input } from '@angular/core';
import { CellDefDirective } from '../cell-def/cell-def.directive';

@Directive({
    selector: '[appColumnDef]',
})
export class ColumnDefDirective {
    cellDef = contentChild(CellDefDirective);
    appColumnDef = input.required<string>();
}
