import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    ElementRef,
    EventEmitter,
    inject,
    Injector,
    Output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, fromEvent } from 'rxjs';
import { skip } from 'rxjs/operators';

@Component({
    selector: 'app-popover-wrapper',
    template: `
        <div class="rounded-xl overflow-hidden popover">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./popover-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class PopoverWrapperComponent implements AfterViewInit {
    private elementRef = inject(ElementRef);

    @Output() close = new EventEmitter();
    destroyRef = inject(DestroyRef);

    ngAfterViewInit(): void {
        fromEvent(document, 'click')
            .pipe(
                skip(1),
                filter(event => !this.elementRef.nativeElement.contains(event.target)),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe(() => {
                this.close.emit();
            });
    }
}
