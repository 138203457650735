import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, input, Input } from '@angular/core';
import { ModalContentContainerComponent } from '../modal-content-container/modal-content-container.component';

@Component({
    selector: 'app-modal',
    template: `
        <div class="modal max-w-[100vw]" [ngClass]="cssClasses()">
            <div class="modal-title">
                <ng-content select="app-modal-title"></ng-content>
            </div>
            <div class="modal-body max-h-[75vh] overflow-auto">
                <ng-content select="app-modal-body"></ng-content>
            </div>
            <div class="modal-footer">
                <ng-content select="app-modal-footer"></ng-content>
            </div>
        </div>
    `,
    styleUrls: ['./modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass],
})
export class ModalComponent {
    private modalContainer = inject(ModalContentContainerComponent);

    @Input() id!: string;
    @Input() title!: string;
    @Input() inputTitle!: string;
    size = input<'sm' | 'md' | 'lg' | 'xl'>('md');

    cssClasses = computed(() => {
        return {
            'w-[400px]': this.size() === 'sm',
            'w-[800px]': this.size() === 'md',
            'w-[1200px]': this.size() === 'lg',
            'w-[1400px]': this.size() === 'xl',
        };
    });

    close() {
        this.modalContainer.onDismiss();
    }
}
