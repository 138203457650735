import { NgClass, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, contentChild, contentChildren, input } from '@angular/core';
import { NgStringPipesModule } from 'ngx-pipes';
import { DecamelizePipe } from '../../../pipes/decamelize.pipe';
import { ColumnDefDirective } from '../../directives/column-def/column-def.directive';
import { EmptyFallbackDirective } from '../../directives/table-empty-fallback/empty-fallback.directive';
import { ColumnDef } from '../../types/column-definition.type';

@Component({
    selector: 'app-table',
    imports: [NgTemplateOutlet, NgClass, DecamelizePipe, NgStringPipesModule],
    template: `
        <table class="table-default">
            <thead class="sticky top-0 bg-white border-b">
                <tr class="">
                    @for (column of columnDefs(); track column.field) {
                        <th [ngClass]="getHeaderClasses(column)">
                            @if (column.headerLabel === undefined) {
                                {{ (column.field?.toString() || column.name)! | decamelize | ucwords }}
                            } @else {
                                {{ column.headerLabel }}
                            }
                        </th>
                    }
                </tr>
            </thead>
            <tbody>
                @for (row of data(); track row.id) {
                    <tr>
                        @for (column of columnDefs(); track column.field) {
                            @let columnDefTemplate = getColumnDefTemplate(column.name);

                            @if (column.name && columnDefTemplate?.cellDef()?.templateRef) {
                                <ng-container
                                    [ngTemplateOutlet]="columnDefTemplate!.cellDef()!.templateRef"
                                    [ngTemplateOutletContext]="{ $implicit: row }"></ng-container>
                            } @else if (column.field) {
                                <td [ngClass]="getCellClasses(column)">
                                    <div [innerHTML]="getCellValue(row, column.valueTransformer, column.field)"></div>
                                </td>
                            } @else {
                                <td></td>
                            }
                        }
                    </tr>
                } @empty {
                    @if (emptyFallbackTemplate()) {
                        <tr>
                            <td class="border-none" [attr.colspan]="columnDefs().length">
                                <ng-container [ngTemplateOutlet]="emptyFallbackTemplate()!.templateRef"></ng-container>
                            </td>
                        </tr>
                    }
                }
            </tbody>
        </table>
    `,
    styleUrl: './table.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T extends { id: any }> {
    columnDefTemplates = contentChildren(ColumnDefDirective);
    columnDefTemplatesByName = computed(() =>
        this.columnDefTemplates().reduce(
            (prev, curr) => {
                if (prev[curr.appColumnDef()]) {
                    throw new Error('The appColumnDef must not be used more than once!');
                }

                return { ...prev, [curr.appColumnDef()]: curr };
            },
            {} as Record<string, ColumnDefDirective>,
        ),
    );

    emptyFallbackTemplate = contentChild(EmptyFallbackDirective);

    columnDefs = input.required<ColumnDef<T>[]>();
    data = input.required<T[]>();

    getColumnDefTemplate(name?: string): ColumnDefDirective | undefined {
        if (!name) {
            return undefined;
        }
        return this.columnDefTemplatesByName()[name];
    }

    getCellClasses(column: ColumnDef<T>) {
        const alignment = `text-${column.cellAlign || 'left'}`;
        const whitespace = `whitespace-${column.whitespace || 'normal'}`;

        return `${alignment} ${whitespace} ${column.cellClasses}`;
    }

    getHeaderClasses(column: ColumnDef<T>) {
        const alignment = `text-${column.headerAlign || 'left'}`;

        return `${alignment} ${column.headerClasses}`;
    }

    getCellValue(row: any, valueTransformer: ((row: any) => string) | undefined, field: ColumnDef<T>['field']) {
        if (valueTransformer) {
            return valueTransformer(row);
        }
        return row[field];
    }
}
