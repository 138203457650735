import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';

@Component({
    selector: 'app-modal-footer',
    template: `
        <div class="modal-footer px-6 pb-6 pt-4" [ngClass]="cssClasses()">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: ['./modal-footer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [NgClass],
})
export class ModalFooterComponent {
    cssClasses = input('flex justify-end');
}
