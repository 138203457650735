import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ModalComponent } from '../modal/modal.component';

@Component({
    selector: 'app-modal-title',
    template: `
        <div class="px-6 pt-6 pb-4 flex">
            <div class="font-bold text-stone-900">
                <ng-content></ng-content>
            </div>

            @if (closeButton()) {
                <button type="button" class="ml-auto" (click)="closeModal()">
                    <fa-icon [icon]="['fas', 'times']"></fa-icon>
                </button>
            }
        </div>
    `,
    styleUrls: ['./modal-title.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [FaIconComponent],
})
export class ModalTitleComponent {
    private modal = inject(ModalComponent);
    closeButton = input(true);

    closeModal() {
        this.modal.close();
    }
}
