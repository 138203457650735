import { ChangeDetectionStrategy, Component, inject, Input, OnInit } from '@angular/core';
import { ConfirmedClickComponent } from '../../../directives/confirmed-click/confirmed-click-component';
import { ModalContentContainerComponent } from '../../../modal/modal-content-container/modal-content-container.component';

@Component({
    selector: 'app-confirmed-delete-dialog',
    template: `
        <div class="h-full w-full flex items-center justify-center" [class.bg-gray-100]="backdrop">
            <div class="bg-white rounded-lg shadow-lg p-4 flex flex-wrap basis-0">
                <h2>{{ body }}</h2>

                <div class="flex mt-3">
                    <button (click)="modal.close.emit(false)">{{ cancelBtn }}</button>
                    <button class="ml-3" (click)="modal.close.emit(true)">{{ confirmBtn }}</button>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./confirmed-cancel-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false,
})
export class ConfirmedCancelDialogComponent implements OnInit, ConfirmedClickComponent {
    modal = inject(ModalContentContainerComponent);

    @Input() title = 'components.confirmed-click.confirmed-delete.title';
    @Input() body = 'Möchtest du wirklich abbrechen? Deine Änderungen gehen dann verloren.';
    @Input() cancelBtn = 'Nein, doch nicht.';
    @Input() confirmBtn = 'Ja, abbrechen!';
    @Input() params: any;
    @Input() backdrop: boolean = false;

    ngOnInit(): void {}
}
